.date-picker {
  box-sizing: border-box;
  border-radius: 4px;
  color: #231F20;
  background-color: inherit;
  border: none;
  outline: none;
  width: 100%;
  padding: 16px;
  font-family: inherit;
  font-size: 15px;

}

.date-picker:focus {
  outline: none;
  background-color: white;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}